// @use "./standard/InputField.scss" as *;

// .login-page {
// 	// min-height: $screenSize;
// 	border: 10px green;
// 	padding: 5% 0;
// 	background-color: rgb(11, 22, 34);
// 	width: 100%;

// 	@media (max-width: 600px) {
// 		padding: 0;
// 	}
// }
@use "./standard/InputField.scss" as *;

.auth-form-wrap {
	border-radius: 4px;
	min-width: 300px;
	margin: 5vh auto;
	background-color: var(--color-bg-primary);
	max-width: 390px;
	width: 100%;
	padding: 40px;
	// margin-top: 8vh;
	-webkit-box-shadow: 0px 0px 3px 1px var(--color-bg-primary);
	box-shadow: 0px 0px 3px 1px var(--color-bg-primary);

	.auth-form-user-select {
		margin-top: 4rem;
		width: 100%;

		label {
			font-size: 15px;
			font-weight: 700;
			color: var(--color-text-light);
		}
	}

	h1 {
		text-align: center;
		color: var(--color-text-primary);
	}

	a {
		text-decoration: none;
		color: var(--color-text-primary);
		transition: color 0.3s ease;
		font-weight: 600;
	}
	a:hover {
		color: var(--color-theme);
	}

	.auth-form {
		width: 100%;
		margin-top: 30px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		@include input-field;
		input{
			width: 100%;
		}

		.auth-button {
			padding: 13px 0;
			width: 100%;
			// margin-top: 20px;
		}

		.auth-form-link {
			display: flex;
			justify-content: space-between;
			margin-top: 4px;
			a {
				font-size: 11px;
			}
		}
	}

	.auth-form-extra {
		margin-top: 3em;
		display: flex;
		align-items: center;
		justify-content: center;
		p {
			color: var(--color-text-secondary);
			font-weight: 500;
		}
	}

	@media (max-width: 600px) {
		max-width: 100%;
		// min-height: 100vh;
		margin: 0;
		padding-left: 20px;
		padding-right: 20px;
	}
}
.auth-grid{
	// margin-bottom: 1.5em;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
	gap: 1rem 2rem;
}
