#progress-bar-wrap {
	background-color: transparent;
	height: 3px;
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 99999;
}

#progress-bar {
	height: 100%;
	background-color: var(--color-theme);
	transition: width 0.7s ease;
	border-radius: 4px;
}
