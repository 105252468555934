.textfield__container {
	display: flex;
	flex-direction: column;

	.input__hideArrow::-webkit-outer-spin-button,
	.input__hideArrow::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	.input__hideArrow {
		-moz-appearance: textfield;
	}
}
.input-wrap{
	svg{
		height:20px;
		width: 20px;
	}
}
.tf-label {
	font-size: 1.1rem;
	color: var(--color-text-light);
	margin: 0 0 8px 3px;
	font-weight: 500;
}
.tf-error {
	color: var(--color-error);
	font-size: 1.1rem;
	margin: 5px 0 0 0;
	text-align: center;
	transition: 0.3s ease;
	font-weight: 500;
}
.text-desc{
	font-size: 1.2rem;
	color: var(--color-text-primary);
}
.confirm-dialog-wrap{
	margin-top: 2rem;
	gap: 10px;
	text-align: right;
	button{
		padding: 8px 15px;
	}
}
.dialog-success{
	color: #49A64C;
	font-weight: 700;
}
.cb-label {
	margin: auto;
	-webkit-user-select: none;
	user-select: none;
	cursor: pointer;
	span {
		display: inline-block;
		vertical-align: middle;
		transform: translate3d(0, 0, 0);
		&:first-child {
			position: relative;
			width: 17px;
			height: 17px;
			border-radius: 3px;
			transform: scale(1);
			vertical-align: middle;
			border: 1px solid var(--color-text-light);
			transition: all 0.2s ease;
			svg {
				position: absolute;
				top: 3px;
				left: 2px;
				fill: none;
				stroke: var(--color-text-button);
				stroke-width: 2;
				stroke-linecap: round;
				stroke-linejoin: round;
				stroke-dasharray: 16px;
				stroke-dashoffset: 16px;
				transition: all 0.3s ease;
				transition-delay: 0.1s;
				transform: translate3d(0, 0, 0);
			}
		}
		&:before {
			content: "";
			width: 100%;
			height: 100%;
			background: var(--color-theme);
			display: block;
			transform: scale(0);
			opacity: 1;
			border-radius: 50%;
		}
		&:last-child {
			font-size: 1.2rem;
			color: var(--color-text-primary);
			margin-left: 8px;
			font-weight: 500;
		}
	}
	&:hover span:first-child {
		border-color: var(--color-theme);
	}
}
.Checkbox__checkbox {
	display: none;
}
.Checkbox__checkbox:checked + .cb-label {
	span {
		&:first-child {
			background: var(--color-theme);
			border-color: var(--color-theme);
			animation: wave 0.4s ease;
			svg {
				stroke-dashoffset: 0;
			}
			&:before {
				transform: scale(3.5);
				opacity: 0;
				transition: all 0.6s ease;
			}
		}
	}
}
@keyframes wave {
	50% {
		transform: scale(0.9);
	}
}

.close-icon-container {
	position: relative;

	.save-icon-wrap {
		opacity: 0.6;
		position: absolute;
		bottom: 5px;
		right: 0;
		left: 0;
		background-color: transparent;
		padding: 0;
		margin: 0;
		svg {
			width: 50%;
			padding: 3px;
			path {
				color: var(--color-theme);
			}
		}
	}
	.close-icon-wrap {
		opacity: 0.3;
		position: absolute;
		background-color: transparent;
		padding: 0;
		margin: 0;
		svg {
			padding: 6px;
			stroke-width: 35px;
			&:hover {
				path {
					color: var(--color-theme);
				}
			}
		}
	}
	.btn-in {
		right: 2px;
		top: 2px;
	}
	.btn-out {
		right: -12px;
		top: -12px;
	}
	.close-icon-wrap svg,
	.save-icon-wrap svg {
		box-shadow: var(--box-shadow);
		border-radius: 4px;
		background-color: var(--color-bg-primary);
	}
	&:hover {
		.close-icon-wrap,
		.save-icon-wrap {
			opacity: 1;
			transition: opacity 0.3s ease-in-out;
		}
	}
}

.bg-primary {
	background-color: var(--color-bg-primary);
	border-radius: 4px;
	box-shadow: var(--box-shadow);
}
// input {
// 	border: 0;
// 	// $bg: var(--color-bg);
// 	background-color: rgba(var(--color-bg-rgb), 0.7);
// 	border-radius: 3px;
// 	font-weight: 600;
// 	font-size: 12px;
// 	margin-bottom: 20px;
// 	color: var(--color-text-primary);
// 	height: 42px;
// 	outline: none !important;
// 	padding: 0 15px;
// }
// input:focus {
// 	box-shadow: 0 0 5px rgba(var(--color-text-rgb), 0.1);
// }

// input::placeholder {
// 	color: rgba(var(--color-text-rgb), 0.3);
// 	// color: var(--color-text-light);
// 	font-size: 13px;
// }
