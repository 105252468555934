@function hexToRGB($hex) {
	@return red($hex), green($hex), blue($hex);
}

$color-theme: #21a099;
$color-theme: #01b6fe;
$color-theme: #22d3ee;
$color-theme: #1fc0a9;
//VARIABLES FOR LIGHT THEME
$color-bg: #edf1f5;
$color-bg-primary: #f7f7f9;
$color-text: #415162;

//VARIABLE FOR DARK THEME
$color-bg-d: #111827;
$color-bg-d: #0f1724;
$color-bg-primary-d: #152232;
$color-text-d: #d6ebee;

* {
	/*
	* React Toastify Customize Color
	*/
	--toastify-color-light: var(--color-bg-primary);
	--toastify-toast-width: 330px;
	--toastify-toast-background: var(--color-bg-primary);
	--toastify-toast-min-height: 35px;
	--toastify-toast-max-height: 800px;
	--toastify-z-index: 9999;
	--toastify-font-size: 12px;
	--toastify-font-weight: 600;
}

:root {
	--color-theme: #{$color-theme};
	--color-theme-rgb: #{hexToRGB($color-theme)};
	--color-bg: #{$color-bg};
	--color-bg-rgb: #{hexToRGB($color-bg)};
	--color-bg-primary: #{$color-bg-primary};
	--color-bg-primary-rgb: #{hexToRGB($color-bg-primary)};
	--color-bg-secondary: #f5f5f5;
	// --color-text-theme: #187772;
	--color-text: #{$color-text};
	--color-text-rgb: #{hexToRGB($color-text)};
	--color-text-primary: #5c728a;
	--color-text-secondary: #9299a1;
	--color-text-light: #adc0d2;
	--color-error: #ff6b6b;
	--color-text-button: rgb(255, 255, 255);
	--toastify-box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%),
		0 2px 15px 0 rgb(0 0 0 / 5%);
	--box-shadow: 0px 0px 5px 0.1px rgba(var(--color-text-rgb), 0.1);
}

html[data-theme="dark"] {
	--color-bg: #{$color-bg-d};
	--color-bg-rgb: #{hexToRGB($color-bg-d)};
	// --color-bg--a: #18253a;
	--color-bg-primary: #{$color-bg-primary-d};
	--color-bg-primary-rgb: #{hexToRGB($color-bg-primary-d)};
	--color-bg-secondary: #1e3048;
	--color-text: #{$color-text-d};
	--color-text-rgb: #{hexToRGB($color-text-d)};
	--color-text-primary: #9fadbd;
	--color-text-secondary: #8596a5;
	--color-text-light: #748899;
	// #7eece7
	--toastify-box-shadow: 0px 0px 15px 0.1px rgba(49, 49, 49, 0.3) !important;
}
