.pnf-wrap{
    height: 100%;
    width: 100%;
    margin-top: 3vw;
    h1{
    font-size: 16vw;
    color: var(--color-theme);
    text-align: center;

    }
    h2{
        color: var(--color-text);
        text-align: center;
    }
        
        a{
            background-color: var(--color-theme);
            padding: 2vh 5vh;
            color: var(--color-text-button);
            font-size: 1.4rem;
            font-weight: 700;
            border-radius: 20px;
        }

}
