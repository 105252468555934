@mixin input-field {
	.textfield__input,
	.mantine-Select-input,
	.mantine-DatePicker-input {
		border: 0;
		background-color: rgba(var(--color-bg-rgb), 0.7);
		border-radius: 3px;
		font-weight: 600;
		font-size: 1.3rem;
		color: var(--color-text-primary);
		height: 42px;
		outline: none !important;
		padding: 0 15px;
		width: 100%;
	}

	.textfield__textarea {
		// resize: none;
		padding: 10px 15px;
		// height: 150px;
		min-height: 150px;
		height: inherit;
	}
	.textfield__input:focus,
	.mantine-Select-input:focus,
	.mantine-DatePicker-input:focus {
		box-shadow: 0 0 5px rgba(var(--color-text-rgb), 0.1);
	}

	.textfield__input::placeholder,
	.mantine-Select-input::placeholder,
	.mantine-DatePicker-input::placeholder,
	input::placeholder {
		color: rgba(var(--color-text-rgb), 0.3);
		// color: var(--color-text-light);
		// font-size: 13px;
	}
}
