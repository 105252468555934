.toast-in {
	-webkit-animation: toast-in 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: toast-in 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes toast-in {
	0% {
		-webkit-transform: translateY(40px);
		transform: translateY(40px);
	}
	100% {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
	}
}
@keyframes toast-in {
	0% {
		-webkit-transform: translateY(40px);
		transform: translateY(40px);
	}
	100% {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
	}
}

.toast-out {
	-webkit-animation: toast-out 0.3s ease-out both;
	animation: toast-out 0.3s ease-out both;
}

@-webkit-keyframes toast-out {
	// 0% {
	// 	opacity: 1;
	// }
	// 100% {
	// 	opacity: 0;
	// }

	0% {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
		opacity: 1;
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(40px);
		transform: translateY(40px);
	}
}
@keyframes toast-out {
	0% {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(40px);
		transform: translateY(40px);
		opacity: 0;
	}
}

.Toastify__toast-container {
	margin-top: 50px;
	
	@media only screen and (max-width: 1040px) {
		margin-top: 0;
	}
}
.Toastify__toast {
	box-shadow: var(--toastify-box-shadow) !important;
}

.Toastify__toast-body {
	font-weight: 600;
	color: var(--color-text-primary);
}
.Toastify__close-button {
	color: var(--color-text-primary) !important;
	align-self: center !important;
}
