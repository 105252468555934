@use "./variables.scss";
@use "./global.scss";
@use "./standard/InputField.scss" as *;
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;0,700;0,900;1,400&display=swap");

@function hexToRGB($hex) {
	@return red($hex), green($hex), blue($hex);
}

@include input-field;
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	transition: background-color 0.3s ease-in-out;

	font-family: Overpass, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen,
		Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

*::selection {
	color: #191919;
	background-color: #f0f0f0;
}

/* width */
::-webkit-scrollbar {
	width: 13px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}
.title{
	color: var(--color-text);
}
/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--color-theme);
	border-radius: 15px;
	border: 3px solid rgba(0, 0, 0, 0);
	background-clip: padding-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: var(--color-theme);
	// border: 3px solid rgba(0, 0, 0, 0);
	background-clip: padding-box;
}

/* Corner */
::-webkit-scrollbar-corner {
	background: transparent;
}

html {
	font-size: 62.5%;
}

body {
	background-color: var(--color-bg);
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	font-size: 1.3rem;
	letter-spacing: 0.4px;
	// line-height: 1.5px;
}

.app-body {
}
