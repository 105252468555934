.mantine-Skeleton-root {
	&::before {
		background: var(--color-bg-primary);
	}
	&::after {
		background: rgba(var(--color-text-rgb), 0.15);
	}
}
.mantine-Dropzone-root {
	background-color: rgba(var(--color-bg-rgb), 0.7);
	box-shadow: var(--box-shadow);
	border-color: var(--color-text-light);
	padding: 0;
	&:hover {
		background-color: var(--color-bg-secondary);
	}
}

.mantine-Select-label,
.mantine-DatePicker-label,
.mantine-DateRangePicker-label,
.mantine-MultiSelect-label,.mantine-TextInput-label {
	font-size: 1.1rem;
	font-weight: 500;
	color: var(--color-text-light);
	// margin: 0px 0px 7px 3px;
	display: inline-block;
}
.mantine-DatePicker-error,
.mantine-Select-error {
	color: var(--color-error) !important;
	font-size: 1.1rem;
	margin: 5px 0 0 0;
	text-align: center;
	transition: 0.3s ease;
	font-weight: 500;
}
.mantine-Select-rightSection {
	svg {
		color: var(--color-error) !important;
	}
}
.mantine-SegmentedControl-root {
	background-color: var(--color-bg-primary);
	box-shadow: var(--box-shadow);

	.mantine-SegmentedControl-labelActive {
		background-color: rgba(var(--color-theme-rgb), 0.7);
		color: var(--color-text-button) !important;
	}
	.mantine-SegmentedControl-label {
		color: var(--color-text);
		font-size: 1.3rem;
		&:hover {
			color: var(--color-theme);
		}
	}
}
.mantine-Modal-modal {
	padding: 10px 15px;
}
.mantine-Modal-title {
	color: var(--color-text);
}
.mantine-Textarea-label {
	font-size: 1.1rem;
	color: var(--color-text-light);
	margin-left: 3px;
	font-weight: 500;
}

.mantine-Accordion-item {
	padding: 10px 0px 15px 0px;
	margin-bottom: 20px;
	border: none;
	border-radius: 4px;
	button {
		padding: 0;
	}
	button:hover {
		background-color: transparent;
	}

	h2 {
		word-break: break-all;
		color: var(--color-text-primary);
		font-size: 1.5rem;
		font-weight: 600;
		transition: color 0.3s ease;
		&:hover {
			color: var(--color-text);
		}
	}
	.accordion-desc {
		padding-top: 5px;
		color: var(--color-text-light);
		font-size: 1.1rem;
		font-weight: 600;
		word-break: break-all;
	}
	svg {
		color: var(--color-text-light);
	}
	.mantine-Accordion-contentInner {
		padding: 20px 0 0 0;
	}
}
.mantine-ActionIcon-root {
	color: var(--color-text-primary);

	svg {
		height: 2.1rem;
		width: 2.1rem;
	}
	&:hover {
		background-color: rgba(var(--color-text-rgb), 0.2);
	}
}
.mantine-MultiSelect-input {
	padding: 4px 10px;
	.mantine-MultiSelect-value {
	background-color: var(--color-bg-secondary);
	box-shadow: var(--box-shadow);
	border-radius: 6px;
	padding-top: 12px;
	padding-bottom: 12px;
	.mantine-MultiSelect-label{
		font-size: 1.2rem;
		font-weight: 500;
	}
	}
}
.mantine-Paper-root,.mantine-MultiSelect-input {
	background-color: var(--color-bg);
	border: 0;
	box-shadow: var(--box-shadow);
	min-width: 150px;
	input::placeholder {
		color: rgba(var(--color-text-rgb), 0.3);
		font-weight: 500;
		font-size: 1.3rem;
	}
	.mantine-Menu-item {
		background-color: var(--color-bg-primary);
		color: var(--color-text-primary);
		transition: background-color 0.2s ease;
		font-size: 1.3rem;
		&:hover {
			background-color: var(--color-bg);
		}
	}
}
.mantine-Select-rightSection > svg {
	color: var(--color-text-light) !important;
}
button {
	background-color: var(--color-theme);
	color: var(--color-text-button);
	cursor: pointer;
	border: 0px;
	font-weight: 700;
	border-radius: 4px;
}
.mantine-Pagination-item{
	background-color: var(--color-bg);
	color: var(--color-text-primary);
	box-shadow: var(--box-shadow);
	border: none;
}
.mantine-Pagination-active{
	background-color: var(--color-theme) !important;
	color: var(--color-text-button)!important;
}
.mantine-Group-root{
	margin-top: 1.3vh;
}
.radio-apply{
	font-size: 1.3rem;
	font-weight: 500;
	color:var(--color-text);
}
.mantine-RadioGroup-radio{
	background-color: var(--color-bg-primary);
	box-shadow: var(--box-shadow);
	
	&:checked{
		background: var(--color-theme);
		border: var(--color-theme);
	}
	
}
button:disabled {
	cursor: not-allowed;
}

a {
	text-decoration: none;
}

.mobile-nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: all 0.5s cubic-bezier(0, 1, 0, 1);
	button {
		padding: 4px 8px;
	}
	svg {
		height: 22px;
		width: 22px;
	}
	p {
		font-size: 2rem;
		color: var(--color-text-secondary);
		font-weight: 700;
	}
}

// padding: 10vh 5vh;
// 	@media (max-width: 1040px) {
// 		padding: 5vh 1vh;
// 	}
// 	@media (max-width: 600px) {
// 		padding: 10px 0;
// 	}
.res-marginX {
	margin-top: 10vh;
	margin-bottom: 10vh;
}
.res-marginY {
	margin-left: 5vw;
	margin-right: 5vw;
}
@media (max-width: 1040px) {
	.res-marginX,
	.res-marginY {
		margin: 3rem;
	}
}
@media (max-width: 768px) {
	.res-margin,
	.res-marginY {
		margin: 0rem;
	}
	.mobile-nav {
		display: flex;
		margin: 10px 10px 0 10px;
	}
}
@media (max-width: 500px) {
}
// {
// 	title: title,
// 	id: id,
// 	has_applied: has_applied,
// 	has_saved: has_saved,
// 	has_liked: has_liked,
// }
.d-flex {
	display: flex;
}

.d-flex-xc {
	justify-content: center;
}
.d-flex-yc {
	align-items: center;
}
.d-flex-fs{
	justify-content: flex-start;
}
.d-flex-sb {
	justify-content: space-between;
}
.d-flex-fe{
	justify-content: flex-end;
	
}
.d-flex-col {
	flex-direction: column;
}
.d-flex-fw{
	flex-wrap: wrap;
}
.d-flex-resp {
	@media (max-width: 500px) {
		flex-direction: column;
	}
}
.bold-500{
	font-weight: 500;
}
.text-primary{
	color: var(--color-text-primary);
}
.text{
	color: var(--color-text);
	
}
.submit-button {
	width: 120px;
	height: 40px;
	border-radius: 4px;
	color: var(--color-text-button);
	font-weight: 600;
	font-size: 1.4rem;

	@media (max-width: 500px) {
		width: 100%;
	}
}

.profile-image {
	height: 25px;
	width: 25px;
	object-fit: cover;
	border-radius: 4px;
	box-shadow: var(--box-shadow);
}

.toast-highlight {
	color: var(--color-theme);
	font-weight: 700;
}

.bg-hover {
	& :hover {
		background-color: rgba(var(--color-text-rgb), 0.1);
		transition: background-color 0.3s ease-in-out;
		border-radius: 5px;
	}
}

.button-hover {
	transition: box-shadow 0.3s ease;
	&:hover {
		-webkit-box-shadow: 0px 0px 8px -1px var(--color-theme);
		box-shadow: 0px 0px 8px -1px var(--color-theme);
	}
}
.color-random-1 {
	background-color: rgba(161, 192, 154, 0.15);
	color: rgb(161, 192, 154);
}
.color-random-2 {
	background-color: rgba(238, 100, 147, 0.15);
	color: rgb(238, 100, 147);
}
.color-random-4 {
	background-color: rgba(192, 154, 153, 0.15);
	color: rgb(192, 154, 153);
}
.color-random-3 {
	background-color: rgba(34, 184, 207, 0.15);
	color: rgb(34, 184, 207);
}
.color-random-5 {
	background-color: rgba(#cd8e13, 0.2);
	color: #cd8e13;
}
.color-random-6 {
	background-color: rgba(#ff6b6b, 0.2);
	color: #ff6b6b;
}
.tag {
	font-size: 1.1rem;
	border-radius: 4px;
	padding: 5px 9px;
	font-weight: 700;
	box-shadow: var(--box-shadow);
	gap:3px
}

.tag-not-submitted {
	background-color: rgba(var(--color-theme-rgb), 0.2);
	color: var(--color-theme);
}
.tag-accepted {
	background-color: rgba(var(--color-theme-rgb), 0.2);
	color: var(--color-theme);
}
.tag-pending {
	background-color: rgba(4, 119, 147, 0.2);
	color: rgb(4, 119, 147);
}
.tag-rejected {
	background-color: rgba(#ff6b6b, 0.2);
	color: #ff6b6b;
}
.tag-color-rejected {
	color: var(--color-error);
}
.tag-color-accepted {
	color: var(--color-theme);
}
.btn-nobg{
	background-color: transparent;
}
.resume-grid {
	margin-bottom: 1.5em;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
	gap: 1rem 5rem;
}
